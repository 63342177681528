*, *:before, *:after {
	-webkit-box-sizing:inherit;
	box-sizing:inherit;
}

html {
	scroll-behavior: smooth;
	scrollbar-color:#000000 #f0f0f0;
	scrollbar-width:thin;
}

body {
  margin:0;
  height:100%;
  overflow:hidden;
  font-family:Arial, sans-serif;
  letter-spacing:-0.01rem;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

::-webkit-scrollbar {
	width:6px;
}

::-webkit-scrollbar-thumb {
	background:#000000;
}

::-webkit-scrollbar-track {
	background-color:#f0f0f0;
}

#root {
  height:100%;
}

h2 {
  font-size:18px;
  font-weight:500;
}

a {
	color:#000000 !important;
}

a:hover {
	color:#ff2189 !important;
	text-decoration:none !important;
}

a[target="_blank"]:not(.memberImage > a[target="_blank"], .socialButtons > a[target="_blank"])::after {
	content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
	margin:0 3px 0 5px;
}

img {
  filter:blur(0);
  -webkit-filter:blur(0);
  transform:translateZ(0);
  -webkit-transform:translateZ(0);
  image-rendering:crisp-edges;
}

small {
  font-size:10px !important;
}

code {
  font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}