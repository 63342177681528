.mainContainer {
    height:100%;
    display:flex;
    align-items:stretch;
    flex:1;
}

.menuContainer {
    width:280px;
    margin-left:70px;
    margin-right:70px;
    background-color:#fff;
    -webkit-transition:margin .25s ease-out;
    transition:margin .25s ease-out;
    overflow-x:hidden;
    overflow-y:auto;
    max-height:100vh;
    -ms-overflow-style:none;
    scrollbar-width:none;
}

.menuContainer::-webkit-scrollbar {
    display:none;
}

.activeMenuContainer {
    width:280px;
    margin-left:-350px;
    margin-right:70px;
    background-color:#fff;
    -webkit-transition:margin .25s ease-out;
    transition:margin .25s ease-out;
}

.menuButton {
    position:absolute;
    top:25px;
    left:25px;
    z-index:4;
}

.menuButton > img {
    transform:rotate(180deg);
    transition:transform .16s ease-in;
}

.menuButton:hover > img {
    transform:rotate(-180deg);
}

.menuActive > img {
    transform:rotate(-180deg);
}

.menuActive:hover > img {
    transform:rotate(0deg);
}

.menuBackground {
    width:inherit;
    height:110%;
    padding-top:20px;
    background-color:#ff6fb9;
    overflow-y:auto;
    overflow-x:hidden;
}

.menuBackground::-webkit-scrollbar {
	width:0px !important;
}

.menu {
    width:inherit;
    padding-top:27px;
    padding-bottom:14px;
    background-color:#fff;
}

.menu ul {
    list-style-type:none;
    padding-left:0px;
    font-weight:bold;
}

.menu a {
	color:#000 !important;
	text-decoration:none !important;
}

.menu a:hover {
	color:#ff2189 !important;
	text-decoration:none !important;
}

.arrowIcon {
    width:11px;
    height:14px;
    display:inline;
    margin-left:6px;
    position:relative;
    transition:transform .16s ease-in;
    top:0.10em;
}

.menu a:hover .arrowIcon, .activeMenu > li > span > .arrowIcon {
    filter:invert(33%) sepia(78%) saturate(5303%) hue-rotate(317deg) brightness(101%) contrast(103%);
    transform:rotate(-90deg);
}

.homeBarA {
    width:100%;
    height:20px;
    margin-top:20px;
    margin-bottom:20px;
    border-left:1px solid #000;
    border-bottom:1px solid #000;
}

.homeBarB {
    width:100%;
    height:20px;
    margin-top:30px;
    margin-bottom:10px;
    border-bottom:1px solid #000;
    background:#fff;
    position:relative;
}

.homeBarB:before {
    content:'';
    position:absolute;
    top:0;
    left:0;
    border-bottom:20px solid #000;
    border-right:20px solid transparent;
    width:0px;
    z-index:1;
}

.homeBarB > div {
    content:'';
    position:absolute;
    top:1px;
    left:0px;
    border-right:20px solid transparent;
    border-bottom:20px solid #fff;
    width:0px;
    z-index:2;
}

.socialButtons {
    width:100%;
    display:inline-flex;
    justify-content:right;
}

.socialButtons > a {
    margin-right:8px;
}

.socialButtons > a > img {
    width:22px;
    height:22px;
}

.socialButtons > a > img:hover {
    filter:invert(33%) sepia(78%) saturate(5303%) hue-rotate(317deg) brightness(101%) contrast(103%);
}

.content {
    width:calc(100vw - 420px);
    min-height:100%;
    padding:0px;
    position:relative;
}

.activeContent {
    width:100%;
    min-height:100%;
    padding:0px;
    position:relative;
}

.contentInner {
    width:inherit;
    height:100vh;
    overflow:auto;
}

.contentMargin {
    margin:47px 30px 40px 30px;
}

.coverCredits {
    position:absolute;
    left:30px;
    bottom:30px;
    z-index:50;
    padding:10px;
    background-color:white;
    filter:drop-shadow(#ff59a6 -5px 5px);
}

.coverCreditsLarge {
    margin-top:0px;
    font-weight:bold;
}

.coverCreditsSmall {
    font-size:15px !important;
    margin-top:-12px;
    margin-bottom:0px;
    font-weight:500;
}

.each-fade {
    display:flex;
    width:100%;
}

.each-fade > div {
    width:inherit;
    overflow:hidden;
}

.each-fade > div img {
    width:100vw;
    height:100vh;
    object-fit:cover;
    margin-left:-420px;
}

.pcLeftMargin {
    margin-left:140px;
}

.pcLeftTitle {
    width:90px;
    height:auto;
    float:left;
    margin-left:15px;
}

.memberList {
    display:flex;
    align-items:flex-start;
    flex-wrap:wrap;
}

.memberBox {
    margin-right:30px;
    width:150px;
}

.productBox {
    margin-right:20px;
    width:300px;
}

.memberImage {
    position:relative;
    cursor:pointer;
	transition:all 0.1s ease-in;
	transition-property:transform, filter, -webkit-transform;
}

.memberImage::before {
    content:'';
    position:absolute;
    top:0;
    right:0;
    border-top:30px solid white;
    border-left:30px solid transparent;
    width:0px;
    z-index:1;
}

.memberImage:hover {
    filter:drop-shadow(#ff59a6 -5px 5px);
	-webkit-transition:all 0.7s ease-out;
	transition:all 0.1s ease-in;
	transition-property:transform, filter, -webkit-transform;
    -webkit-transform:translate(5px,-5px);
    transform:translate(5px,-5px);
}

.memberName p {
    font-size:14px;
    font-weight:500;
    margin-top:8px;
    margin-bottom:20px;
}

.memberLink {
    font-weight:700;
    font-size:10px !important;
}

.memberBox small, .productBox small {
    font-size:11px !important;
}

.modal {
    position:fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.5);
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:5;
}

.modalContent {
    width:auto;
    height:auto;
    max-height:94vh;
    background-color:#fff;
    -webkit-box-shadow:5px 5px 5px 0px rgb(38,38,38,0.4);
    -moz-box-shadow:5px 5px 5px 0px rgb(38,38,38,0.4);
    box-shadow:5px 5px 5px 0px rgb(38,38,38,0.4);
}

.modalBody {
    position:relative;
    height:inherit;
    width:inherit;
}

.modalBody > button {
    background:#000;
    border:1px solid #fff;
    color:#fff;
    padding:6px 10px;
    position:absolute;
    top:-13px;
    right:-16px;
    z-index:7;
}

.modalBody > button:hover {
    background:#ef86bc;
}

.modalBody > img {
    width:auto;
    max-width:98vw;
    height:auto;
    max-height:94vh;
    display:block;
}

@media (min-width:992px) {
    .menuButton {
        display:none;
    }
}

@media (max-width:992px) {
    html {
        height:100%;
    }

    .menuContainer {
        margin-left:-350px;
    }

    .activeMenuContainer {
        width:100%;
        margin-left:30px;
        margin-right:30px;
    }

    .activeMenuContainer > .menuBackground > .menu {
        width:100%;
    }

    .menuBackground {
        height:100% !important;
    }

    .menuButton {
        transform:rotate(0deg);
    }
    
    .menuButton:hover > img {
        transform:rotate(180deg);
    }
    
    .menuActive > img {
        transform:rotate(180deg);
    }

    .menuActive:hover > img {
        transform:rotate(0deg);
    }

    .socialButtons {
        margin-bottom:25px;
    }

    .content {
        width:100% !important;
    }

    .contentInner {
        height:100%;
    }

    .contentMargin {
        padding-top:30px;
    }

    .activeContent {
        width:calc(100vw - 420px);
        display:none;
    }

    .coverCredits {
        bottom:100px;
    }

    .each-fade > div img {
        margin-left:0px;
    }

    .pcLeftMargin {
        margin-left:0px;
    }

    .pcLeftTitle {
        display:none;
    }

    .memberBox, .productBox {
        margin-left:auto;
        margin-right:auto;
    }

    .modalContent, .modalBody > img {
        width:88vw;
        height:auto;
    }
}